"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.router = exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
_vue.default.use(_vueRouter.default);
var router = new _vueRouter.default({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
    path: '/auth/login',
    name: 'auth',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/auth/login'));
      });
    },
    meta: {
      layoutFull: true
    }
  }, {
    path: '/auth/resend-email',
    name: 'resendEmail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/auth/resendEmail'));
      });
    },
    meta: {
      layoutFull: true
    }
  }, {
    path: '/auth/signup',
    name: 'signup',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/auth/signup'));
      });
    },
    meta: {
      layoutFull: true
    }
  }, {
    path: '/auth/recover-password',
    name: 'recover-password',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/auth/recoverPassword'));
      });
    },
    meta: {
      layoutFull: true
    }
  }, {
    path: '/confirm-registration',
    name: 'confirm-registration',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/confirmRegistration'));
      });
    },
    meta: {
      layoutFull: true
    }
  }, {
    path: '/change-password',
    name: 'change-password',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/auth/changePassword'));
      });
    },
    meta: {
      layoutFull: true
    }
  }, {
    path: '/home',
    name: 'home',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/home'));
      });
    },
    redirect: '/farm-management/list',
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/services/schedule',
    name: 'schedule-of-services',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/services/schedule'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/services/types',
    name: 'types-services',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/services/typesServices'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/products/types',
    name: 'types-products',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/products/TypesProducts'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/account-management',
    name: 'AccountManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/accountManagement'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/account-management/detail',
    name: 'AccountManagementDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/accountManagement/details'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/services/archiveds',
    name: 'archived-services',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/services/archiveds'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/services/edit/list',
    name: 'edit-list-services',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/services/editService'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/ocurrrences/list',
    name: 'occurrences-list',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/occurrences/list'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/ocurrrences/ratings',
    name: 'occurrences-ratings',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/occurrences/ratings'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/team/list',
    name: 'team-list',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/team'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/farm-management/list',
    name: 'FarmList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/farm'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/farm-management/dashboard',
    name: 'Dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/dashboard'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/farm-management/map',
    name: 'map',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/farm/map'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/farm-management/routes',
    name: 'routes',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/RoutesFarmManagement'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/farm-management/routes/:id',
    name: 'routesUser',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/RoutesFarmUserManagement'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/farm-management/productiveareas',
    name: 'ProductiveAreas',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/productiveAreas/list'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/farm-management/productiveareas/:id',
    name: 'ProductiveAreasDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/productiveAreas/detail'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/farm-management/farm-data',
    name: 'FarmData',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/farmData'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/stock',
    name: 'Estoque',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/stock'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/identifiers',
    name: 'Identificadores',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/identifiers'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/payment-list-farms',
    name: 'PaymentListFarms',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/payment'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/payment-list-farms/details/:id',
    name: 'PaymentFarmsDetails',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/payment/details'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/faq',
    name: 'faq',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/faq'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/import-balance',
    name: 'importBalance',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/importBalance'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/traceabilitytags',
    name: 'traceabilitytags',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/traceabilitytags'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/valid-tag',
    name: 'valid-tag',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/validTag'));
      });
    },
    meta: {
      layoutFull: true
    }
  }, {
    path: '/customfields/list',
    name: 'custom-fields-list',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/customFields/ListCustomFields.vue'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/customfields/list-client',
    name: 'custom-fields-list-client',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/customFields/ListCustomFieldsClient.vue'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/custom-reports',
    name: 'custom-reports',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/flow/customReports'));
      });
    },
    meta: {
      requiresAuth: true
    }
  }, {
    path: '*',
    redirect: '/auth/login'
  }]
});
exports.router = router;
var _default = router;
exports.default = _default;