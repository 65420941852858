"use strict";

var _vue = _interopRequireDefault(require("vue"));
var _App = _interopRequireDefault(require("./App.vue"));
var _i18n = _interopRequireDefault(require("./i18n"));
require("./plugins/plugins-kit");
require("./filters");
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _vuexRouterSync = require("vuex-router-sync");
require("@mdi/font/css/materialdesignicons.min.css");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
_vue.default.config.productionTip = false;
(0, _vuexRouterSync.sync)(_store.default, _router.default);
_router.default.beforeEach(function (to, from, next) {
  if (to.matched.some(function (record) {
    return record.meta.requiresAuth;
  })) {
    if (!_store.default.state.auth.user.token) {
      next({
        path: '/auth/login'
      });
    } else {
      if (!_store.default.state.farm.farm && to.name !== 'FarmList') {
        next({
          path: '/farm-management/list'
        });
      } else {
        next();
      }
    }
  } else {
    next();
  }
});
new _vue.default({
  i18n: _i18n.default,
  store: _store.default,
  router: _router.default,
  render: function render(h) {
    return h(_App.default);
  }
}).$mount('#app');