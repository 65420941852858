"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-import-tab-balance"
  }, [_c("div", {
    staticClass: "help-text"
  }, [_vm._v("\n    Antes de importar os dados da balança, verifique se está com a API de integração devidamente instalada,\n    para isso acesse o link "), _c("a", {
    attrs: {
      href: _vm.linkAPI,
      target: "_blank"
    }
  }, [_vm._v("API Balança")]), _vm._v(" e faça o download.\n  ")]), _vm._v(" "), _c("div", {
    staticClass: "btn-import-content"
  }, [_c("b-button", {
    on: {
      click: _vm.importAction
    }
  }, [_vm._v("\n      Importa dados balança cacho\n    ")])], 1), _vm._v(" "), _c("div", {
    staticClass: "import-load-content"
  }, [!_vm.dataImport.total ? _c("h4", {
    staticClass: "no-load"
  }, [_vm._v("\n      Nenhum dado no momento\n    ")]) : _c("div", {
    staticClass: "list"
  }, [_c("h4", {
    staticClass: "no-load"
  }, [_vm._v("\n        " + _vm._s(_vm.dataImport.total) + " registro" + _vm._s(_vm.dataImport.total > 1 ? "s" : "") + " importados\n      ")])])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;