"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    staticClass: "modal-custom-field",
    attrs: {
      width: 650,
      active: true,
      scroll: "keep"
    }
  }, [_c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "card-content"
  }, [_c("div", {
    staticClass: "media"
  }, [_c("div", {
    staticClass: "media-content"
  }, [_c("button", {
    staticClass: "btn-x-close",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.cancelModal
    }
  }, [_c("i", {
    staticClass: "icon-ic_close"
  })]), _vm._v(" "), _c("p", {
    staticClass: "title"
  }, [_vm._v("\n            " + _vm._s(_vm.title) + _vm._s(_vm.item.register) + "\n          ")])])]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("form", [_c("app-input", {
    attrs: {
      value: _vm._f("datePost")(_vm.dateCreated),
      label: "Data de criação",
      placeholder: "data",
      disabled: ""
    }
  }), _vm._v(" "), _vm.item.plot ? _c("b-field", {
    attrs: {
      label: "Local"
    }
  }, _vm._l(_vm.item.plot, function (local, idx) {
    return _c("div", {
      key: idx
    }, [_c("app-input", {
      attrs: {
        placeholder: "Local"
      },
      model: {
        value: _vm.form.plot[idx],
        callback: function callback($$v) {
          _vm.$set(_vm.form.plot, idx, $$v);
        },
        expression: "form.plot[idx]"
      }
    })], 1);
  }), 0) : _vm._e(), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("app-select", {
    attrs: {
      label: "Cliente(Comprador)",
      placeholder: "Selecione o cliente",
      error: _vm.$v.form.clientId.$error,
      items: _vm.listCustomers,
      option: "id",
      "name-item": "name",
      expanded: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.clientId, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.clientId.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.clientId, "$model", $$v);
      },
      expression: "$v.form.clientId.$model"
    }
  }), _vm._v(" "), _c("b-button", {
    staticClass: "btn-add-field",
    on: {
      click: _vm.openModalNewCustomer
    }
  }, [_vm._v("\n              Novo cliente\n            ")])], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      expanded: "",
      label: "Tipo de pagamento"
    }
  }, [_c("div", {
    staticClass: "block"
  }, _vm._l(_vm.listTypePayments, function (itemTP, idx) {
    return _c("b-radio", {
      key: idx,
      attrs: {
        "native-value": itemTP.id,
        name: "tipoPayment"
      },
      model: {
        value: _vm.$v.form.paymentConditions.$model,
        callback: function callback($$v) {
          _vm.$set(_vm.$v.form.paymentConditions, "$model", $$v);
        },
        expression: "$v.form.paymentConditions.$model"
      }
    }, [_vm._v("\n                " + _vm._s(_vm.item.description) + "\n              ")]);
  }), 1)]), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("app-input", {
    attrs: {
      label: "Data da Colheita",
      expanded: "",
      mask: ["##/##/####"],
      masked: true,
      placeholder: "DD/MM/AAAA"
    },
    model: {
      value: _vm.$v.form.harvestDate.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.harvestDate, "$model", $$v);
      },
      expression: "$v.form.harvestDate.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      label: "Identificação interna",
      expanded: ""
    },
    model: {
      value: _vm.$v.form.identificationDocument.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.identificationDocument, "$model", $$v);
      },
      expression: "$v.form.identificationDocument.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "content-products"
  }, [_c("b-field", {
    attrs: {
      label: "Produtos",
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      expanded: ""
    }
  }, [_c("app-select", {
    attrs: {
      label: "Produto",
      placeholder: "Selecione o produto",
      error: _vm.$v.formSell.productId.$error,
      items: _vm.listProducts,
      option: "id",
      "name-item": "name",
      expanded: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.formSell.productId, "$error", $event);
      }
    },
    model: {
      value: _vm.productId,
      callback: function callback($$v) {
        _vm.productId = $$v;
      },
      expression: "productId"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      expanded: ""
    }
  }, [_c("app-select", {
    attrs: {
      label: "Variedade",
      placeholder: "Selecione a variedade",
      error: _vm.$v.formSell.varietyId.$error,
      items: _vm.listVarieties,
      option: "id",
      "name-item": "name",
      expanded: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.formSell.varietyId, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.formSell.varietyId.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.formSell.varietyId, "$model", $$v);
      },
      expression: "$v.formSell.varietyId.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      expanded: ""
    }
  }, [_c("app-select", {
    attrs: {
      label: "Cultivar",
      placeholder: "Selecione o cultivo",
      error: _vm.$v.formSell.cultivationId.$error,
      items: _vm.listCultivations,
      option: "id",
      "name-item": "name",
      expanded: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.formSell.cultivationId, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.formSell.cultivationId.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.formSell.cultivationId, "$model", $$v);
      },
      expression: "$v.formSell.cultivationId.$model"
    }
  })], 1)], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      label: "Caixas",
      expanded: ""
    }
  }, [_c("money", _vm._b({
    staticClass: "input",
    attrs: {
      placeholder: "Informe a quantidade de caixas",
      type: "text",
      id: "uname",
      expanded: ""
    },
    model: {
      value: _vm.$v.formSell.qtd.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.formSell.qtd, "$model", $$v);
      },
      expression: "$v.formSell.qtd.$model"
    }
  }, "money", _vm.money, false))], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Preço",
      expanded: ""
    }
  }, [_c("money", _vm._b({
    staticClass: "input",
    attrs: {
      placeholder: "Informe o preço",
      prefix: "R$ ",
      type: "text",
      id: "uname",
      expanded: ""
    },
    model: {
      value: _vm.formSell.price,
      callback: function callback($$v) {
        _vm.$set(_vm.formSell, "price", $$v);
      },
      expression: "formSell.price"
    }
  }, "money", _vm.money, false))], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Desconto",
      expanded: ""
    }
  }, [_c("money", _vm._b({
    staticClass: "input",
    attrs: {
      placeholder: "Informe o valor do desconto",
      prefix: "R$ ",
      type: "text",
      id: "uname",
      expanded: ""
    },
    model: {
      value: _vm.formSell.offValue,
      callback: function callback($$v) {
        _vm.$set(_vm.formSell, "offValue", $$v);
      },
      expression: "formSell.offValue"
    }
  }, "money", _vm.money, false))], 1)], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("app-input", {
    attrs: {
      label: "% de 1ª",
      placeholder: "Informe o % de 1ª",
      expanded: ""
    },
    model: {
      value: _vm.formSell.percent1,
      callback: function callback($$v) {
        _vm.$set(_vm.formSell, "percent1", $$v);
      },
      expression: "formSell.percent1"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      label: "% de 2ª",
      placeholder: "Informe o % de 2ª",
      expanded: ""
    },
    model: {
      value: _vm.formSell.percent2,
      callback: function callback($$v) {
        _vm.$set(_vm.formSell, "percent2", $$v);
      },
      expression: "formSell.percent2"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      label: "% de 3ª",
      placeholder: "Informe o % de 3ª",
      expanded: ""
    },
    model: {
      value: _vm.formSell.percent3,
      callback: function callback($$v) {
        _vm.$set(_vm.formSell, "percent3", $$v);
      },
      expression: "formSell.percent3"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      label: "Valor de 1ª",
      expanded: ""
    }
  }, [_c("money", _vm._b({
    staticClass: "input",
    attrs: {
      placeholder: "Informe o valor de 1ª",
      prefix: "R$ ",
      value: _vm.price1,
      type: "text",
      id: "uname",
      expanded: "",
      disabled: ""
    }
  }, "money", _vm.money, false))], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Valor de 2ª",
      expanded: ""
    }
  }, [_c("money", _vm._b({
    staticClass: "input",
    attrs: {
      placeholder: "Informe o valor de 2ª",
      prefix: "R$ ",
      value: _vm.price2,
      type: "text",
      id: "uname",
      expanded: "",
      disabled: ""
    }
  }, "money", _vm.money, false))], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Valor de 3ª",
      expanded: ""
    }
  }, [_c("money", _vm._b({
    staticClass: "input",
    attrs: {
      placeholder: "Informe o valor de 3ª",
      prefix: "R$ ",
      value: _vm.price3,
      type: "text",
      id: "uname",
      expanded: "",
      disabled: ""
    }
  }, "money", _vm.money, false))], 1)], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      label: "Valor total R$",
      expanded: ""
    }
  }, [_c("money", _vm._b({
    staticClass: "input",
    attrs: {
      placeholder: "Informe o valor de 1ª",
      prefix: "R$ ",
      value: _vm.total,
      type: "text",
      id: "uname",
      expanded: "",
      disabled: ""
    }
  }, "money", _vm.money, false))], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Kg",
      expanded: ""
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v("\n                  Kg Totais\n                  "), _c("b-tooltip", {
          attrs: {
            type: "is-dark",
            label: "Peso considerado da caixa: 22Kg"
          }
        }, [_c("b-icon", {
          attrs: {
            size: "is-small",
            icon: "help-circle-outline"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c("money", _vm._b({
    staticClass: "input",
    attrs: {
      placeholder: "Informe o valor de 2ª",
      prefix: "Kg ",
      value: _vm.kg,
      type: "text",
      id: "uname",
      expanded: "",
      disabled: ""
    }
  }, "money", _vm.money, false))], 1)], 1)], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Observação"
    }
  }, [_c("b-input", {
    attrs: {
      type: "textarea",
      minlength: "10",
      maxlength: "1000",
      placeholder: "Gostaria de informar alguma nota"
    },
    model: {
      value: _vm.form.note,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "note", $$v);
      },
      expression: "form.note"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Entrega (Se houver)",
      grouped: ""
    }
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.address.$error,
      label: "Endereço",
      placeholder: "Informe o endereço",
      expanded: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.address, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.address.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.address, "$model", $$v);
      },
      expression: "$v.form.address.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      error: _vm.$v.form.city.$error,
      label: "Cidade",
      placeholder: "Informe a cidade",
      expanded: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.city, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.city.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.city, "$model", $$v);
      },
      expression: "$v.form.city.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    staticClass: "row-save-form"
  }, [_c("b-button", {
    staticClass: "btn-save-form",
    on: {
      click: _vm.resolved
    }
  }, [_vm._v("\n              Salvar\n            ")])], 1)], 1)])])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;